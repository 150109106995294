
























































































import { MetaInfo } from 'vue-meta'
import { Component, Mixins, Ref, Watch, Vue } from 'vue-property-decorator'
import { sortBy } from 'lodash'
import shave from 'shave'

// components
import Select from '@/components/_uikit/controls/Select.vue'
import MasterGroupsSearch from '@/components/_uikit/search/MasterGroupsSearch.vue'
import Parameters from '@/components/_uikit/Parameters.vue'
// mixins
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import CalendarMixin from '@/mixins/CalendarMixin'
// types
import {
  CalendarLargeItemResource, IManagerCalendarFilter,
  NameValueResource,
} from '@/store/types'
import DictionaryModule from '@/store/modules/dictionary'
import { formatDate, formatDateRange } from '@/utils/functions'
import MentorCalendarModule from '@/store/modules/mentor/calendar'

@Component({
  components: {
    MasterGroupsSearch,
    Parameters,
    Select,
  },
})
export default class Calendar extends Mixins(MasterGroupMixin, CalendarMixin) {
  @Ref()
  private calendarRef!: Vue

  // issue with TS and Vuetify Calendar
  // https://gist.github.com/Nicklas2751/da1fbe81878df7bb531fa4978c0ce7a8
  private get calendarInstance (): Vue & { getStartOfWeek: (format: any) => any, getEndOfWeek: (format: any) => any, scrollToTime: (time: number | string | { hour: number, minute: number }) => boolean } {
    return this.$refs.calendarRef as Vue & { getStartOfWeek: (format: any) => any, getEndOfWeek: (format: any) => any, scrollToTime: (time: number | string | { hour: number, minute: number }) => boolean }
  }

  private get title(): string {
    if (this.chosenType === 'day') {
      return formatDate(this.filter.startAt)
    }
    if (this.chosenType === 'week') {
      return formatDateRange(this.filter.startAt, this.filter.endAt)
    }
    return (this.$refs.calendarRef as any).title
  }

  private exercises: CalendarLargeItemResource[] = []
  private lessons: CalendarLargeItemResource[] = []

  private get filter() {
    return MentorCalendarModule.calendarFilter
  }

  private set filter(filter: IManagerCalendarFilter) {
    MentorCalendarModule.setCalendarFilter(filter)
    this.fetchCalendarData()
  }

  private mounted() {
    DictionaryModule.fetchConstants()
      .catch(this.notifyError)
  }

  private handleFilter(field: keyof IManagerCalendarFilter, value: any) {
    this.filter = {
      ...this.filter,
      [field]: value,
    }
  }

  private handleChange(event: any) {
    this.filter = {
      ...this.filter,
      endAt: this.chosenType !== 'day' ? this.calendarInstance.getEndOfWeek(event.end).date : event.start.date,
      startAt: this.chosenType !== 'day' ? this.calendarInstance.getStartOfWeek(event.start).date : event.end.date,
    }
  }


  private fetchCalendarData() {
    // Note: стоить поправить, но много где сейчас напрямую передаётся this.currentMasterGroup, где инициализирующее значение -1 - number
    // поправить на null - значит нужно везде предусматривать, что текущей группы нет - TS ругается на несовпадение типов
    if (this.currentMasterGroupID !== -1) {
      this.fetchCalendar(this.currentMasterGroupID, this.filter)
        .then((response) => {
          this.lessons = response.lesson ? response.lesson : []
          this.exercises = response.exercise ? response.exercise : []

          const events = [...response.exercise, ...response.lesson, ...response.usefulMaterial]

          this.$nextTick(() => {
            events.forEach((item) => {
              shave(`#event-text-${item.type}-${item.id}`, 36, { spaces: false })
            })
          })

          if (this.chosenType === 'day' && this.exercises.concat(this.lessons).length > 0) {
            this.calendarInstance.scrollToTime(formatDate(sortBy(this.exercises.concat(this.lessons), ['spendingAt'])[0].spendingAt, 'HH:mm', this.isLocalTimezone))
            // this.calendarInstance.scrollToTime(formatDate(sortBy(this.exercises.concat(this.lessons), ['deadlineAt'])[0].deadlineAt, 'HH:mm'))
          }
        })
        .catch(this.notifyError)
    }
  }

  private get chosenType() {
    if (this.$vuetify.breakpoint.name === 'xs' && this.currentChosenOption.value === 'week') {
      this.handleSwitchScheduleType('day')
      return 'day'
    } else if (this.currentChosenOption.value === 'day' && this.$vuetify.breakpoint.width > 451) {
      this.handleSwitchScheduleType('week')
      return 'week'
    } else {
      return this.currentChosenOption.value
    }
  }

  private get events() {
    return this.largeEvents // mixin
  }

  private get eventHeight() {
    if (this.$vuetify.breakpoint.name === 'xs') {
      return this.currentChosenOption.value === 'month' ? 4 : 40
    }
    return 40
  }

  private handleSwitchScheduleType (action: string) {
    this.currentChosenOption = this.menu.find((item: NameValueResource) => item.value === action) || this.menu[0]
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Календарь',
    }
  }

  @Watch('currentMasterGroupID')
  watchMasterGroup() {
    this.fetchCalendarData()
  }
}
